import React from 'react';
import styled from 'react-emotion';
import { Query } from 'react-apollo';

import theme from '@jetshop/ui/utils/theme';

import GridProduct from '../../CategoryPage/GridProduct';
import ProductCarousel from '../../ui/ProductCarousel';
import getFavoritesQuery from './getFavoritesQuery.gql';

const ProductGridWrapper = styled('div')`
  max-width: 1200px;
  padding: 40px 10px 0 10px;
  margin: 0 auto 30px auto;
  h2 {
    font-family: ${theme('fonts.body')};
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-weight: 300;
  }
  ${theme('below.lg')} {
    max-width: 100%;
    padding: 20px 0;
    margin: auto;
  }
`;

const Favorites = ({ id, title }) => {
  if (!id) {
    return null;
  }
  id = parseFloat(id);
  return (
    <React.Fragment>
      <Query query={getFavoritesQuery} variables={{ id: id }}>
        {({ loading, error, data }) => {
          if (loading) {
            return 'Loading...';
          }
          if (error) return null;
          if (!data.category) {
            return null;
          }
          return (
            <ProductGridWrapper>
              <h2>{title}</h2>
              <ProductCarousel
                products={data.category.products.result}
                listName={data.category.name}
                categoryPath={data.category.primaryRoute}
                loading={loading}
                ProductComponent={GridProduct}
              />
            </ProductGridWrapper>
          );
        }}
      </Query>
    </React.Fragment>
  );
};

export default Favorites;
