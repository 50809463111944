import React from 'react';
import styled from 'react-emotion';
import MaxWidth from '../../Layout/MaxWidth';
import Image from '@jetshop/ui/Image/Image';
import StartPageCategoriesQuery from './StartPageCategoriesQuery.gql';
import { Query } from 'react-apollo';
import CategoryLink from '@jetshop/ui/CategoryLink';
import t from '@jetshop/intl';

const Container = styled('div')`
  padding-top: 48px;
  padding-bottom: 62px;
  ${({ theme }) => theme.below.sm} {
    padding-top: 32px;
    padding-bottom: 38px;
  }
`;

const Title = styled('h2')`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  ${({ theme }) => theme.below.sm} {
    font-size: 21px;
  }
`;

const ItemTitle = styled('h2')`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  color: #000;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: 200ms;
`;

const ImageContainer = styled('div')`
  /* height: 218px; */
  transition: 200ms;
  filter: grayscale(1);
`;

const Item = styled(CategoryLink)`
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  &:hover {
    ${ItemTitle} {
      background-color: #2f80ed;
      color: white;
    }
    ${ImageContainer} {
      filter: grayscale(0);
    }
  }

  ${({ theme }) => theme.above.lg} {
    flex: 1;
  }

  ${({ theme }) => theme.below.lg} {
    width: calc(25% - 24px);
  }

  ${({ theme }) => theme.below.md} {
    width: 180px;
    max-width: 24vw;
    flex-shrink: 0;
  }

  ${({ theme }) => theme.below.sm} {
    width: 210px;
    max-width: 55vw;
  }
`;

const ItemsContainer = styled('div')`
  display: flex;
  margin-left: -12px;
  margin-right: -12px;
  ${({ theme }) => theme.below.lg} {
    flex-wrap: wrap;
    justify-content: center;
  }
  ${({ theme }) => theme.below.md} {
    overflow-x: auto;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
`;

// @todo: is there an alternative method to running an individual query for each category?
// @todo: rather than using the category images, assigning individual images in JSON, as category images were meant to be horizontal (hero)

const StartPageCategories = ({ categories }) => (
  <MaxWidth>
    <Container>
      <Title>{t('Selected Categories')}</Title>
      <ItemsContainer>
        {categories.map((cat, index) => (
          <Query
            variables={{ id: parseInt(cat) }}
            query={StartPageCategoriesQuery}
            key={index}
          >
            {({ data }) =>
              data && Object.keys(data).length > 0 ? (
                <Item category={data.category}>
                  <ImageContainer>
                    <Image
                      aspect={'59:73'}
                      cover={true}
                      src={
                        data.category.images && data.category.images.length > 0
                          ? data.category.images
                          : null
                      }
                    />
                  </ImageContainer>
                  <ItemTitle>{data.category.name}</ItemTitle>
                </Item>
              ) : null
            }
          </Query>
        ))}
      </ItemsContainer>
    </Container>
  </MaxWidth>
);

export default StartPageCategories;
