import React from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { Link } from 'react-router-dom';

const Outer = styled('div')`
  overflow: hidden;
  display: block;
  position: relative;
  padding: 2rem 0;
  ${theme('below.md')} {
    padding: 10px 0;
  }
`;
const Wrapper = styled('div')`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 1800px;
  ${theme('below.md')} {
    gap: 5px;
    padding: 0 1rem 0 1rem;
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: flex-start;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      display: none;
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Item = styled('div')`
  a {
    color: inherit;
    text-decoration: none;
  }
  &:hover {
    .icon {
      transform: scale(1.1);
    }
    .title {
      transform: translateY(-5px);
    }
  }
  .title {
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    transition: all 0.2s ease;
    ${theme('below.md')} {
      font-size: 11px;
    }
  }
  .icon-wrapper {
    background: #f7f7f7;
    padding: 50px;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 10px;
    ${theme('below.md')} {
      padding: 15px;
      height: 70px;
      width: 70px;
      margin: 0 5px 5px 5px;
    }
  }
  .icon {
    height: 50px;
    width: auto;
    object-fit: contain;
    transition: all 0.2s ease;
    ${theme('below.md')} {
      height: 30px;
    }
  }
`;

const IconRow = ({ icons }) => {
  return (
    <Outer>
      <Wrapper>
        {icons?.map((item, index) => {
          return (
            <Item key={index}>
              <Link to={item?.link}>
                <div className="icon-wrapper">
                  <img
                    className="icon"
                    src={item?.icon || ''}
                    alt={item?.title}
                  />
                </div>
                <h3 className="title">{item?.title}</h3>
              </Link>
            </Item>
          );
        })}
      </Wrapper>
    </Outer>
  );
};

export default IconRow;
