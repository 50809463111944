import React from 'react';
import styled, { css } from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';

import { Below } from '@jetshop/ui/Breakpoints';

const ProductCarouselWrapper = styled('div')`
  width: 100%;
  padding: 0;

  ${theme('above.lg')} {
    position: relative;
  }
`;

const ProductCarouselContainer = styled('div')`
  overflow-x: scroll;
  position: relative;

  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    display: none;
  }
  ${theme('above.lg')} {
    overflow: hidden;
    padding-left: 0;
  }
  padding-left: 13px;
  ${theme('below.lg')} {
    padding-bottom: 10px;
  }

  &:before {
    content: '';
    height: 1px;
    width: calc(100% - 20px);
    margin: auto;
    background: #ececec;
    margin-bottom: 20px;
  }
  &:after {
    content: '';
    height: 1px;
    width: calc(100% - 20px);
    margin: auto;
    background: #ececec;
    margin-bottom: 20px;
  }
`;
const Carousel = styled('div')`
  display: flex;
  position: relative;
  transition: all 0.35s ease-in-out;
  ${theme('above.lg')} {
    left: 0;
  }
  > div {
    width: ${props => props.productWidth}vw;
    flex-grow: 0;
    ${theme('above.lg')} {
      width: 20% !important;
    }
    ${props =>
      props.productWidth * props.products > 100 &&
      css`
        flex: 1 0 auto;
      `}
    > a {
      display: block;
      ${theme('above.lg')} {
        margin: 3px;
      }
    }
  }
`;

const SlideNav = styled('div')`
  position: absolute;
  width: 30px;
  height: 100%;
  background: #fff;
  display: none;
  top: 0;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }
  svg {
    width: 35px;
    height: 35px;
  }

  ${props =>
    props.direction === 'left'
      ? css`
          left: -40px;
          svg {
            transform: rotate(90deg);
          }
        `
      : css`
          right: -40px;
          align-items: center;
          svg {
            transform: rotate(-90deg);
          }
        `}

  ${theme('above.lg')} {
    display: flex;
    padding: 1.5rem;
    align-items: center;
    ${props =>
      props.direction === 'left'
        ? css``
        : css`
            right: -40px;
            align-items: center;
          `}
  }
`;

const productPlaceholder = {
  name: '',
  id: 'placeholder',
  images: []
};

export default class ProductCarousel extends React.Component {
  state = {
    active: 0,
    touchX: 0,
    products: 0,
    toShow: 5
  };
  componentDidMount() {
    if (this.props.products) {
      if (this.state.products === 0) {
        this.setState({ products: this.props.products.length });
      }
    }
    if (this.props.className && this.props.className.indexOf('recently') > -1) {
      this.setState({ toShow: 10 });
    }
  }

  swipeLeft = () => {
    if (
      this.state.active + this.state.toShow <=
      this.state.products - this.state.toShow
    ) {
      this.setState({ active: this.state.active + this.state.toShow });
    } else {
      if (this.state.active + this.state.toShow < this.state.products) {
        this.setState({
          active:
            this.state.active +
            (this.state.products - this.state.toShow - this.state.active)
        });
      }
    }
  };
  swipeRight = () => {
    if (this.state.active === 0) {
      return;
    }

    if (this.state.active - this.state.toShow < 0) {
      this.setState({ active: 0 });
    } else {
      this.setState({ active: this.state.active - this.state.toShow });
    }
  };

  render() {
    const {
      className,
      products,
      loading,
      categoryPath,
      ProductComponent,
      productWidth
    } = this.props;
    const productList =
      products.length === 0 && loading
        ? Array(5).fill(productPlaceholder)
        : products;
    return (
      <ProductCarouselWrapper className={className + ' carousel'}>
        {(productWidth || 20) * productList.length > 100 &&
          this.state.active > 0 && (
            <SlideNav direction="left" onClick={this.swipeRight}>
              &gt;
            </SlideNav>
          )}
        <ProductCarouselContainer
          data-testid="product-grid"
          className={className}
        >
          {products && (
            <Below breakpoint="lg">
              {matches =>
                matches ? (
                  <Carousel
                    products={this.state.products}
                    productWidth={productWidth ? productWidth : 44}
                    style={{
                      left: '-' + this.state.active * 44 + 'vw',
                      transform:
                        this.state.active > 0 ? 'translateX(15vw)' : 'none'
                    }}
                  >
                    {productList.map((product, index) => (
                      <ProductComponent
                        loading={loading}
                        key={`${index}${product.id}`}
                        product={product}
                        categoryPath={categoryPath}
                      />
                    ))}
                  </Carousel>
                ) : (
                  <Carousel
                    products={this.state.products}
                    productWidth={productWidth ? productWidth : 44}
                    style={{
                      left:
                        '-' +
                        this.state.active * (100 / this.state.toShow) +
                        '%',
                      transform: 'translateX(0%)'
                    }}
                  >
                    {productList.map((product, index) => (
                      <ProductComponent
                        loading={loading}
                        key={index}
                        product={product}
                        categoryPath={categoryPath}
                      />
                    ))}
                  </Carousel>
                )
              }
            </Below>
          )}
        </ProductCarouselContainer>
        {(productWidth || 100 / this.state.toShow) * productList.length > 100 &&
          this.state.active < productList.length - this.state.toShow && (
            <SlideNav direction="right" onClick={this.swipeLeft}>
              &lt;
            </SlideNav>
          )}
      </ProductCarouselWrapper>
    );
  }
}
