import React from 'react';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import Image from '@jetshop/ui/Image';
import theme from '@jetshop/ui/utils/theme';
import productBlockQuery from './productBlockQuery.gql';
import { ProductGrid } from '../../CategoryPage/ProductGrid';
import ProductCard from '../../CategoryPage/ProductCard';

const Column = styled('div')`
  width: 50%;
  position: relative;

  ${theme('below.lg')} {
    width: 100%;
    margin-bottom: 20px;
  }

  &.image-column {
    > div:first-child {
      height: calc(100% - 66px) !important;
      ${theme('below.lg')} {
        height: 400px !important;
      }
    }
  }
`;

const Wrapper = styled('div')`
  width: 100%;
  max-width: 80rem;
  padding: 0 2rem;
  margin: auto;
  position: relative;
  display: flex;
  margin-bottom: 40px;

  ${theme('below.lg')} {
    flex-direction: column;
    padding: 0 20px;
  }

  ${Column} {
    &:nth-child(1) {
      padding-right: 0.5rem;
      padding-left: 0;
      ${theme('below.lg')} {
        padding: 0;
      }
    }
    &:nth-child(2) {
      padding-left: 0.5rem;
      padding-right: 0;
      ${theme('below.lg')} {
        padding: 0;
      }
    }
  }

  &.left {
    flex-direction: row-reverse;
    ${theme('below.lg')} {
      flex-direction: column-reverse;
    }
    ${Column} {
      &:nth-child(1) {
        padding-left: 0.5rem;
        padding-right: 0;
        ${theme('below.lg')} {
          padding: 0;
        }
      }
      &:nth-child(2) {
        padding-right: 0.5rem;
        padding-left: 0;
        ${theme('below.lg')} {
          padding: 0;
        }
      }
    }
  }

  ${theme('below.lg')} {
  }
`;
const Content = styled('div')`
  color: ${props => props.textcolor};
  text-align: center;
  h1 {
    font-size: 4rem;
    margin: 0 0 15px 0;
    line-height: 1;
  }

  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  right: 0;
  padding: 5px;
  text-align: center;
  display: flex;

  &.right {
    justify-content: flex-end;
  }

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.top {
    align-items: flex-start;
  }

  &.bottom {
    align-items: flex-end;
  }

  &.middle {
    align-items: center;
  }

  .white {
    color: white;
  }
  .black {
    color: black;
  }

  > div {
    height: fit-content;
    margin: 1rem;
  }
`;
const CTA = styled('span')`
  display: inline-block;
  color: black;
  text-decoration: none;
  padding: 20px 25px;
  background: white;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 3px;
  font-family: ${theme('fonts.body')};
  font-weight: bold;
  transition: all, 0.2s ease;
  min-width: 160px;
  &:hover {
    background: black;
    color: white;
  }
`;

export const BlockLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  ${theme('below.lg')} {
    height: 330px;
    display: block;
    width: 100%;
  }
`;

const StyledProductGrid = styled(ProductGrid)`
  grid-template-columns: repeat(2, 1fr) !important;
  grid-row-gap: 1em;
  margin-bottom: 0 !important;
`;

const ProductColumn = ({ articleNumbers }) => {
  const { data, loading, error } = useQuery(productBlockQuery, {
    variables: {
      articleNumbers: articleNumbers
    },
    errorPolicy: 'ignore'
  });

  if (loading || error) {
    return null;
  }

  return (
    <React.Fragment>
      {data?.products?.length > 0 && (
        <StyledProductGrid
          products={data.products}
          loading={loading}
          ProductComponent={ProductCard}
        />
      )}
    </React.Fragment>
  );
};

const ProductBlock = ({
  products,
  image,
  title,
  link,
  buttonText,
  textColor,
  align,
  position = 'center middle'
}) => {
  return (
    <Wrapper className={align}>
      <Column>
        <ProductColumn articleNumbers={products} />
      </Column>
      <Column className="image-column">
        <BlockLink to={link}>
          <Image cover fillAvailableSpace src={image} alt={title} />
          <Content
            textcolor={textColor ? textColor : 'white'}
            className={position}
          >
            <div>
              <h1>{title}</h1>
              {buttonText ? <CTA>{buttonText}</CTA> : null}
            </div>
          </Content>
        </BlockLink>
      </Column>
    </Wrapper>
  );
};

export default ProductBlock;
