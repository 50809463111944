import React, { Component } from 'react';
import styled, { css } from 'react-emotion';

import t from '@jetshop/intl';
import ProductImage from '@jetshop/ui/ProductList/ProductImage';
import theme from '@jetshop/ui/utils/theme';
import ProductLink from '@jetshop/ui/ProductLink';
import { Price } from '@jetshop/ui/Price';
import AddToCartButton from '../ProductPage/AddToCart/AddToCartButton';
import AddFavoriteButton from '../FavoriteList/AddFavoriteButton';

export const ProductWrapper = styled('div')`
  padding: 0 10px;
  margin-bottom: 20px;
  ${theme('above.sm')} {
    width: 20%;
  }
  ${theme('below.lg')} {
    width: 45%;
    padding: 5px;
  }
`;
export const ProductInnerWrapper = styled('div')`
  height: 100%;
`;
export const ProductBlockLink = styled(ProductLink)`
  color: #000;
  text-decoration: none;
`;
export const ProductDetails = styled('div')`
  text-align: left;
`;
export const Name = styled('p')`
  margin-bottom: 0;
  font-size: 0.85rem;
  line-height: 1.3;
  font-weight: 300;
  text-align: left;
  padding-right: 30px;
  text-transform: uppercase;
  font-family: ${theme('fonts.body')};

  ${theme('below.lg')} {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0;
    white-space: nowrap;
  }
`;
export const ProductImageWrapper = styled('div')`
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  padding: 10px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.02);
    z-index: 1;
    pointer-events: none;
  }

  span {
    background: #f5f4f4;
    display: block;
    padding: 5px;
    font-size: 12px;
    margin-bottom: 3px;
  }
  div {
    justify-content: flex-start;
  }

  &.not-buyable {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.6);
      z-index: 0;
      pointer-events: none;
    }
  }
`;
export const NewImage = styled('img')`
  max-width: 80%;
  max-height: 80%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  transform: scale(1);
  transition: transform, 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`;

export const StyledPrice = styled(Price)`
  font-size: 0.85rem;
  font-weight: 300;
  display: flex;
  ${theme('above.sm')} {
    font-size: 1rem;
  }

  ${Price.New} {
    color: #000;
    margin-right: 5px;
  }
  ${Price.Old} {
    color: #949494;
  }
  ${Price.Normal} {
    font-weight: bold;
  }
`;

const ProductDetailRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ProductNameRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${ProductBlockLink} {
    ${theme('below.lg')} {
      width: 85%;
    }
  }
`;

const SubName = styled('span')`
  display: block;
  font-weight: 300;
  font-size: 12px;
  color: #757575;
  margin-bottom: 5px;
  line-height: 1.2;
  padding-right: 30px;
`;

const SoldOutBanner = styled('h3')`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-transform: uppercase;
  z-index: 1;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
  background: none;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 1px;
`;

function getProductColor(array) {
  for (var i = 0; i < array.length; i++) {
    const element = array[i];
    if (element.key === 'displaycolor') {
      return element.stringValue;
    }
  }
}

class GridProduct extends Component {
  initialState = {
    image: this.props.product.images && this.props.product.images[0]
  };
  state = this.initialState;

  componentDidMount = () => {
    if (this.isSecondImage()) {
      this.initialState = {
        image: this.props.product.images && this.props.product.images[1]
      };
      this.setState(this.initialState);
    }
  };

  isSecondImage = () => {
    let imageIndex = this.props.index + 1;
    if (
      this.props.product.images &&
      this.props.product.images.length > 1 &&
      this.props.categoryPage
    ) {
      if (imageIndex % 9 === 0 || imageIndex % 9 === 8) {
        return true;
      }
    }
    return false;
  };

  switchToInitialImage = () => {
    this.setState(this.initialState);
  };

  switchToSecondImage = () => {
    if (this.isSecondImage()) {
      this.setState({ image: this.props.product.images[0] });
    } else if (this.props.product && this.props.product.images[1]) {
      this.setState({ image: this.props.product.images[1] });
    }
  };

  render() {
    const {
      product,
      categoryPath,
      loading,
      className = '',
      buyButton = false
    } = this.props;

    return (
      <ProductWrapper data-testid="product" className={className}>
        <ProductInnerWrapper>
          <ProductBlockLink
            product={product}
            categoryPath={categoryPath}
            className={
              loading &&
              css`
                opacity: 0.5;
              `
            }
            onMouseEnter={this.switchToSecondImage}
            onMouseLeave={this.switchToInitialImage}
          >
            {product.images[0] && (
              <ProductImageWrapper
                className={
                  product && product.stockStatus && !product.stockStatus.buyable
                    ? 'not-buyable'
                    : null
                }
              >
                {product &&
                product.stockStatus &&
                !product.stockStatus.buyable ? (
                  <SoldOutBanner>{t('Sold out')}</SoldOutBanner>
                ) : null}
                <ProductImage
                  sizes="400"
                  aspect={'1:1'}
                  image={this.state.image && this.state.image.url}
                  badges={product.badges}
                />
              </ProductImageWrapper>
            )}
          </ProductBlockLink>
          <ProductDetails>
            <ProductNameRow>
              <ProductBlockLink
                product={product}
                categoryPath={categoryPath}
                className={
                  loading &&
                  css`
                    opacity: 0.5;
                  `
                }
              >
                <Name>{product.name}</Name>
              </ProductBlockLink>
              <AddFavoriteButton product={product} />
            </ProductNameRow>
            <ProductDetailRow>
              {/* <SubName>{product.subName}</SubName> */}
              <StyledPrice
                price={product.price}
                recommendedPrice={product.recommendedPrice}
                previousPrice={product.previousPrice}
              />
            </ProductDetailRow>

            {buyButton && <AddToCartButton product={product} inStock={true} />}
          </ProductDetails>
        </ProductInnerWrapper>
      </ProductWrapper>
    );
  }
}
export default GridProduct;
