import React from 'react';
import styled from 'react-emotion';
import MaxWidth from '../../Layout/MaxWidth';
import Image from '@jetshop/ui/Image/Image';
import { TrendLink } from '../../ui/Button';
import { Above } from '@jetshop/ui/Breakpoints';

const Container = styled('div')`
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  align-items: center;
  flex-direction: row;
  ${({ theme }) => theme.below.sm} {
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 0;
  }
`;

const Title = styled('h1')`
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 18px;
  ${({ theme }) => theme.below.md} {
    font-size: 26px;
  }
`;

const Description = styled('p')`
  width: 400px;
  max-width: 100%;
  margin-bottom: 18px;
  line-height: 1.45em;
  ${({ theme }) => theme.below.sm} {
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledButton = styled(TrendLink)`
  width: 176px;
  height: 38px;
  ${({ theme }) => theme.below.sm} {
    margin-left: auto;
    margin-right: auto;
  }
`;

const Column = styled('div')`
  ${({ theme }) => theme.above.sm} {
    &:first-child {
      margin-right: 1rem;
    }
    &:last-child {
      margin-left: 1rem;
    }
  }
`;

const ImageColumn = styled(Column)`
  width: 60%;
  ${({ theme }) => theme.below.md} {
    width: 55%;
  }
  ${({ theme }) => theme.below.sm} {
    width: 100%;
  }
`;

const TextColumn = styled(Column)`
  width: 40%;
  ${({ theme }) => theme.below.md} {
    width: 45%;
  }
  ${({ theme }) => theme.below.sm} {
    width: 100%;
    padding-bottom: 48px;
    text-align: center;
  }
`;

const StartPageCampaign = ({ campaign }) => {
  if (!campaign) return null;
  const rightAlignment = campaign.alignment && campaign.alignment === 'right';
  return (
    <Above breakpoint="sm">
      {matches =>
        matches ? (
          <MaxWidth>
            <Container>
              {rightAlignment && <ImageCol src={campaign.image.url} />}
              <CampaignContent campaign={campaign} />
              {!rightAlignment && <ImageCol src={campaign.image.url} />}
            </Container>
          </MaxWidth>
        ) : (
          <Container>
            <MaxWidth>
              <CampaignContent campaign={campaign} />
            </MaxWidth>
            <ImageCol src={campaign.image.url} />
          </Container>
        )
      }
    </Above>
  );
};

const CampaignContent = ({ campaign }) => (
  <TextColumn>
    <Title>{campaign.title}</Title>
    <Description>{campaign.description}</Description>
    <StyledButton to={campaign.button.url}>{campaign.button.text}</StyledButton>
  </TextColumn>
);

const ImageCol = ({ src }) => (
  <ImageColumn>
    <Image aspect={'640:359'} src={src} />
  </ImageColumn>
);

export default StartPageCampaign;
