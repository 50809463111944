import React from 'react';
import styled from 'react-emotion';

import theme from '@jetshop/ui/utils/theme';

const Text = styled('h2')`
  font-size: 2rem;
  margin-bottom: 20px;
  letter-spacing: 1px;
  text-align: center;
`;

const Title = ({ title }) => {
  return <Text>{title}</Text>;
};

export default Title;
