import React from 'react';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';

import { Above, Below } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image';
import theme from '@jetshop/ui/utils/theme';

const Wrapper = styled('div')`
  width: 100%;
  max-width: 76rem;
  margin: auto;
  position: relative;
  ${theme('below.lg')} {
  }
`;
const Content = styled('div')`
  color: ${props => props.textcolor};
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  right: 0;
  padding: 5px;
  text-align: center;
  display: flex;

  &.right {
    justify-content: flex-end;
  }

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.top {
    align-items: flex-start;
  }

  &.bottom {
    align-items: flex-end;
  }

  &.middle {
    align-items: center;
  }

  .white {
    color: white;
  }
  .black {
    color: black;
  }

  > div {
    height: fit-content;
    margin: 1rem;
  }

  h1 {
    font-size: 4rem;
    margin: 0 0 15px 0;
    line-height: 1;
    ${theme('below.lg')} {
      font-size: 3rem;
    }
  }
`;
const CTA = styled('span')`
  display: inline-block;
  color: black;
  text-decoration: none;
  padding: 20px 25px;
  background: white;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 3px;
  font-family: ${theme('fonts.body')};
  font-weight: bold;
  transition: all, 0.2s ease;
  min-width: 160px;
  &:hover {
    background: black;
    color: white;
  }
`;

export const BlockLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  img {
    max-width: 100%;
  }
`;

const Featured = ({
  imageSrc,
  title,
  link,
  buttonText,
  textColor,
  position = 'center middle'
}) => {
  return (
    <Wrapper>
      <BlockLink to={link}>
        {imageSrc?.indexOf('.gif') > -1 ? (
          <img src={imageSrc} alt={title} />
        ) : (
          <>
            <Above breakpoint="lg">
              <Image aspect={'16:9'} cover src={imageSrc} alt={title} />
            </Above>
            <Below breakpoint="lg">
              <Image aspect={'5:4'} cover src={imageSrc} alt={title} />
            </Below>
          </>
        )}
        <Content
          textcolor={textColor ? textColor : 'white'}
          className={position}
        >
          <div>
            <h1>{title}</h1>
            {link && buttonText && <CTA>{buttonText}</CTA>}
          </div>
        </Content>
      </BlockLink>
    </Wrapper>
  );
};

export default Featured;
