import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import styled from 'react-emotion';
import MaxWidth from '../Layout/MaxWidth';
import LoadingPage from '../LoadingPage';
import StartPageCampaign from './Content/StartPageCampaign';
import StartPageCategories from './Content/StartPageCategories';
import StartPageHero from './Content/StartPageHero';
import StartPageProductGrid from './Content/StartPageProductGrid';
import startPageQuery from './StartPageQuery.gql';
import Favorites from './StartPageComponents/Favorites';
import LayoutBlock from './StartPageComponents/LayoutBlock';
import TwoBlocks from './StartPageComponents/TwoBlocks';
import BoxRow from './StartPageComponents/BoxRow';
import ProductBlock from './StartPageComponents/ProductBlock';
import Instagram from './StartPageComponents/Instagram';
import Featured from './StartPageComponents/Featured';
import FeaturedNew from './StartPageComponents/FeaturedNew';
import FeaturedNewExternal from './StartPageComponents/FeaturedNewExternal';
import Title from './StartPageComponents/Title';
import ContentBlock from './StartPageComponents/ContentBlock';
import TopButtons from './StartPageComponents/TopButtons';
import ProductRow from './StartPageComponents/ProductRow';
import DepictProductRow from '../Depict/DepictProductRow';
import { CategoryPageReviews } from '../CategoryPage/CategoryPageReviews';
import IconRow from './StartPageComponents/IconRow';

const HtmlBlockWrapper = styled('div')`
  max-width: 600px;
  margin: auto;
  padding: 2rem 1rem;
  text-align: center;
  h2 {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
`;

const HtmlBlock = ({ html }) => {
  return (
    <HtmlBlockWrapper>
      <div
        dangerouslySetInnerHTML={{
          __html: html
        }}
      />
    </HtmlBlockWrapper>
  );
};
const startPageComponents = {
  Hero: StartPageHero,
  Categories: StartPageCategories,
  Campaign: StartPageCampaign,
  Favorites: Favorites,
  LayoutBlock: LayoutBlock,
  TwoBlocks: TwoBlocks,
  ProductBlock: ProductBlock,
  Instagram: Instagram,
  Featured: Featured,
  Title: Title,
  ContentBlock: ContentBlock,
  FeaturedNew: FeaturedNew,
  FeaturedNewExternal: FeaturedNewExternal,
  TopButtons: TopButtons,
  ProductRow: ProductRow,
  HtmlBlock: HtmlBlock,
  BoxRow: BoxRow,
  DepictProductRow: DepictProductRow,
  Lipscore: CategoryPageReviews,
  IconRow: IconRow
};

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MainSectionWrapper = styled(MaxWidth)`
  width: 100%;
  align-self: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  ${({ theme }) => theme.below.sm} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const StartPage = ({ location: { pathname, key }, startPageId }) => {
  return (
    <Fragment>
      <StartPageWrapper>
        <Query
          variables={startPageId && { startPageId: startPageId }}
          query={startPageQuery}
        >
          {result => {
            const { loading, data } = result;
            if (loading) return <LoadingPage />;
            if (!data) return null;
            if (
              data.startPage &&
              data.startPage.items &&
              data.startPage.items.length > 0
            ) {
              return (
                <>
                  <StartPageRenderer
                    result={result}
                    startPageComponents={startPageComponents}
                    ProductGrid={StartPageProductGrid}
                    LoadingPage={LoadingPage}
                  />
                  {/* <CategoryPageReviews /> */}
                </>
              );
            } else return null;
          }}
        </Query>
      </StartPageWrapper>
    </Fragment>
  );
};

export default StartPage;
