import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image/Image';
import { Price } from '@jetshop/ui/Price';
import theme from '@jetshop/ui/utils/theme';
import React from 'react';
import styled from 'react-emotion';
import { ReactComponent as Cross } from '../../../svg/Cross.svg';
import { TrendLink } from '../../ui/Button';
import cartQuery from '../../Cart/CartQuery.gql';

const Container = styled('aside')`
  ${theme('above.sm')} {
    width: 320px;
  }
  ${theme('below.sm')} {
    width: 100%;
  }
  background-color: ${theme('colors.white')};
`;

const CheckoutButton = styled(TrendLink)`
  background: ${({ theme }) => theme.colors.black};
  letter-spacing: 1px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 14px;
  font-weight: bold;
  transition: all, 0.2s ease;
  text-transform: uppercase;
  &:hover {
    background: #383838;
  }

  &.outlet {
    background: ${({ theme }) => theme.colors.outletRed};
    &:hover {
      background: ${({ theme }) => theme.colors.outletRedLight};
    }
  }
`.withComponent('a');

const ProductImageWrapper = styled('div')`
  width: 5rem;
  margin-right: 1rem;
`;

const ProductCheckoutContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  a {
    text-decoration: none;
    :hover {
      opacity: 0.8;
      text-decoration: none;
      color: white;
    }
  }
`;

const Product = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: ${theme('space.2')};
`;

const ProductDetails = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: black;

  .price {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
  }
`;

const CloseButton = styled('button')`
  background: none;
  border: 0;
  appearance: none;
  svg {
    width: 1em;
    height: 1em;
    use {
      fill: #000000;
    }
  }
`;

const Header = styled('h3')`
  font-size: 1.5rem;
  text-transform: uppercase;
`;

const ProductName = styled('p')`
  font-size: ${theme('fontSizes.0')};
  line-height: 1.3;
  text-transform: uppercase;
  font-size: 14px;
`;

const Error = styled('p')`
  color: red;
  margin-top: ${theme('space.1')};
  font-size: ${theme('fontSizes.0')};
`;

const ProductToast = ({
  product,
  cart,
  closeToast,
  selectedVariation,
  error
}) => {
  const pricing = selectedVariation || product;
  const track = useTracker();
  return (
    <Container data-testid="product-toast">
      <ProductCheckoutContainer>
        <Product>
          {product.images.length > 0 && (
            <ProductImageWrapper>
              <Image
                src={product.images[0].url}
                sizes={80}
                aspect={'1:1'}
                alt={product.images[0].alt}
                quality={80}
              />
            </ProductImageWrapper>
          )}
          <ProductDetails>
            {error ? (
              <Header>{t('Failed adding to bag')}</Header>
            ) : (
              <Header>{t('Added to bag')}</Header>
            )}
            <ProductName>{product.name}</ProductName>
            {!error && (
              <Price
                className="price"
                price={pricing.price}
                previousPrice={pricing.previousPrice}
              />
            )}
            {error && (
              <Error>
                {t('An error occurred. Details:')}
                <ul>
                  {error.graphQLErrors && error.graphQLErrors.length > 0 ? (
                    error.graphQLErrors.map(({ message, locations, path }) => (
                      <li key={message}>{t(message)}</li>
                    ))
                  ) : (
                    <li>{t(error.message)}</li>
                  )}
                </ul>
              </Error>
            )}
          </ProductDetails>
          <CloseButton onClick={closeToast}>
            <Cross />
          </CloseButton>
        </Product>
        {cart && cart.externalCheckoutUrl && (
          <ChannelContext.Consumer>
            {({ selectedChannel }) => (
              <CheckoutButton
                className={selectedChannel.id === 6 ? 'outlet' : 'not-outlet'}
                href={`${cart.externalCheckoutUrl}&channelCountry=${selectedChannel.country.code}`}
                onClick={() => {
                  track(trackCartCheckoutEvent({ cart }));
                }}
              >
                {t('To checkout')}
              </CheckoutButton>
            )}
          </ChannelContext.Consumer>
        )}
      </ProductCheckoutContainer>
    </Container>
  );
};
const ProductToastWrapper = props => (
  <CartProvider query={cartQuery}>
    {({ data }) => <ProductToast {...props} cart={data && data.cart} />}
  </CartProvider>
);

export default ProductToastWrapper;
