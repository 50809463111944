import Image from '@jetshop/ui/Image';
import theme from '@jetshop/ui/utils/theme';
import React from 'react';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';

const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  max-width: 76rem;
  margin: 1rem 0;
  position: relative;
  align-self: center;

  > div {
    flex: 1;
  }

  ${theme('below.lg')} {
    padding: 0 1rem;
    margin: 1rem 0;
    flex-direction: column;
  }

  h2 {
    color: ${theme('colors.black')};
    font-family: ${theme('fonts.body')};
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 1px;
    text-align: center;
    line-height: 1;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  p {
    padding: 0 10px;
    text-align: center !important;
    margin-bottom: 5px;
    font-size: 14px;
    ${theme('below.lg')} {
      padding: 0 20px;
    }
    ${theme('below.sm')} {
      padding: 0;
    }
  }

  div:first-child {
    margin-left: 0;
  }
  div:last-child {
    margin-right: 0;
  }
`;

const CTA = styled('span')`
  text-transform: none;
  text-decoration: underline;
  font-size: 14px;
`;

const CTALink = styled(Link)`
  color: inherit;
  text-decoration: none;
  text-align: center;
`;

const ContentBlockWrapper = styled('div')`
  display: inline-block;
  margin: 0 0.5rem;
  flex: 1;
  text-align: center;
  ${theme('below.lg')} {
    margin-bottom: 20px;
  }
`;

const ContentBlock = ({ contentBlocks }) => {
  return (
    <Wrapper>
      {contentBlocks?.map((contentBlock, index) => (
        <ContentBlockWrapper key={index}>
          <Image
            aspect={'16:9'}
            cover
            src={contentBlock.imageSrc}
            alt={contentBlock.title}
            sizes={'500'}
          />
          <h2>{contentBlock.title}</h2>
          <p>{contentBlock.content}</p>

          {contentBlock.buttons?.map((button, index) => (
            <CTALink key={index} to={button.link}>
              <CTA>{button.buttonText}</CTA>
            </CTALink>
          ))}
        </ContentBlockWrapper>
      ))}
    </Wrapper>
  );
};

export default ContentBlock;
