import React from 'react';
import styled from 'react-emotion';
import MaxWidth from '../../Layout/MaxWidth';
import { ProductGrid } from '../../CategoryPage/ProductGrid';
import t from '@jetshop/intl';

const Container = styled('div')`
  margin-bottom: 40px;
`;

const Title = styled('h2')`
  font-size: 2rem;
  margin-bottom: 20px;
  letter-spacing: 1px;
  text-align: center;
`;

// The horizontal scroll is styled in a way that on any resolution smaller than 'lg', a part of the second/third item can be seen, indicating it is a horizontally scrollable container.
const StyledProductGrid = styled(ProductGrid)`
  flex-wrap: nowrap;
  ${({ theme }) => theme.below.md} {
    overflow-x: auto;
    .product-card {
      width: 300px;
      flex-shrink: 0;
      max-width: 40vw;
    }
  }
  ${({ theme }) => theme.below.sm} {
    .product-card {
      max-width: 60vw;
      width: 340px;
    }
  }
  ${({ theme }) => theme.below.xs} {
    .product-card {
      max-width: 75vw;
    }
  }

  @supports (display: grid) {
    grid-template-columns: repeat(4, 1fr);
    margin: 0;
    ${({ theme }) => theme.below.md} {
      grid-template-columns: repeat(10, 1fr);
    }
  }
`;

const StartPageProductGrid = props => (
  <Container>
    <MaxWidth>
      <Title>{t('Popular Products')}</Title>
      <StyledProductGrid {...props} imageSizes={[1 / 2, 1 / 2, 1 / 4]} />
    </MaxWidth>
  </Container>
);

export default StartPageProductGrid;
