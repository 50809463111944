import React from 'react';
import styled from 'react-emotion';

import { Above } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image';
import theme from '@jetshop/ui/utils/theme';

import { MainSectionWrapper } from '../../StartPage/StartPage';
import { Link } from 'react-router-dom';

export const CtaButton = styled('span')`
  display: inline-block;
  color: black;
  text-decoration: none;
  padding: 20px 25px;
  background: white;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 3px;
  font-family: ${theme('fonts.body')};
  font-weight: bold;
  transition: all, 0.2s ease;
  min-width: 160px;
  &:hover {
    background: black;
    color: white;
  }
`;

export const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  ${theme('below.md')} {
    flex-wrap: wrap;
  }
`;

export const BlockLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

export const Column = styled('div')`
  width: 50%;

  ${theme('below.md')} {
    width: 100%;
  }
`;
export const Box = styled('div')`
  width: 100%;
  position: relative;
  padding: 0.5em;
  ${theme('below.lg')} {
    padding: 10px 20px;
  }
`;
export const BoxImage = styled('div')`
  position: relative;
  display: flex;
  align-items: center;

  img  {
    width: 100%;
  }
`;
export const BoxContent = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  right: 0;
  padding: 5px;
  text-align: center;
  display: flex;

  &.right {
    justify-content: flex-end;
  }

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.top {
    align-items: flex-start;
  }

  &.bottom {
    align-items: flex-end;
  }

  &.middle {
    align-items: center;
  }

  .white {
    color: white;
  }
  .black {
    color: black;
  }

  h2 {
    font-size: 3rem;
    line-height: 1;
    margin-bottom: 15px;
  }

  > div {
    height: fit-content;
    margin: 1rem;
  }
`;

const Wrap = styled('div')`
  > div {
    padding: 0 1.5rem;
    ${theme('below.lg')} {
      padding: 0;
    }
  }
`;

const FirstBlock = ({
  image,
  title,
  color,
  buttonText,
  buttonUrl,
  responsive,
  position = 'center middle'
}) => (
  <Box>
    <BlockLink to={buttonUrl}>
      <BoxImage className={color}>
        <Image
          aspect={'600:770'}
          crop={true}
          cover={true}
          src={image}
          alt={title}
        />
      </BoxImage>
      <BoxContent className={'responsive-' + responsive + ' ' + position}>
        <div>
          <h2 className={color}>{title}</h2>
          {buttonText ? (
            <CtaButton to={buttonUrl}>{buttonText}</CtaButton>
          ) : null}
        </div>
      </BoxContent>
    </BlockLink>
  </Box>
);
const SecondBlock = ({
  image,
  title,
  color,
  buttonText,
  buttonUrl,
  responsive,
  position = 'center middle'
}) => (
  <Box>
    <BlockLink to={buttonUrl}>
      <BoxImage className={color}>
        <Above breakpoint="md">
          {matches =>
            matches ? (
              <Image
                aspect={'30:19'}
                crop={true}
                cover={true}
                src={image}
                alt={title}
              />
            ) : (
              <Image
                aspect={'4:5'}
                crop={true}
                cover={true}
                src={image}
                alt={title}
              />
            )
          }
        </Above>
      </BoxImage>
      <BoxContent className={'responsive-' + responsive + ' ' + position}>
        <div>
          <h2 className={color}>{title}</h2>
          {buttonText ? (
            <CtaButton to={buttonUrl}>{buttonText}</CtaButton>
          ) : null}
        </div>
      </BoxContent>
    </BlockLink>
  </Box>
);
const ThirdBlock = ({
  image,
  title,
  color,
  buttonText,
  buttonUrl,
  responsive,
  position = 'center middle'
}) => (
  <Box>
    <BlockLink to={buttonUrl}>
      <BoxImage className={color}>
        <Above breakpoint="md">
          {matches =>
            matches ? (
              <Image
                aspect={'30:19'}
                crop={true}
                cover={true}
                src={image}
                alt={title}
              />
            ) : (
              <Image
                aspect={'4:5'}
                crop={true}
                cover={true}
                src={image}
                alt={title}
              />
            )
          }
        </Above>
      </BoxImage>
      <BoxContent className={'responsive-' + responsive + ' ' + position}>
        <div>
          <h2 className={color}>{title}</h2>
          {buttonText ? (
            <CtaButton className="maxWidth" to={buttonUrl}>
              {buttonText}
            </CtaButton>
          ) : null}
        </div>
      </BoxContent>
    </BlockLink>
  </Box>
);

const LayoutBlock = ({ firstBlock, secondBlock, thirdBlock }) => {
  return (
    <Wrap>
      <MainSectionWrapper>
        <Container>
          <Column>
            <FirstBlock {...firstBlock} />
          </Column>
          <Column>
            <SecondBlock {...secondBlock} />
            <ThirdBlock {...thirdBlock} />
          </Column>
        </Container>
      </MainSectionWrapper>
    </Wrap>
  );
};

export default LayoutBlock;
