import React from 'react';
import styled from 'react-emotion';

import t from '@jetshop/intl';

import { ReactComponent as SVG } from '../../../../svg/Star.svg';
import { theme } from '../../../Theme';
import { Above } from '@jetshop/ui/Breakpoints';
import { LipscoreProductRating } from '../../Lipscore/LipscoreProductRating';
import { ReviewSwiper } from './ReviewSwiper';

const ReviewWrapper = styled('div')`
  color: #000;
  background: ${theme.colors.background};
  width: 100%;
  padding: 0;
  margin-top: 30px;
  overflow: hidden;

  .swiper {
    display: flex;
  }

  .swiper-wrapper {
    display: flex;
  }
  ${theme.below.lg} {
    padding: 40px 0;
  }
  .review-block-title {
    text-align: center;
    margin: 0 0 30px 0;
    font-size: 28px;
    font-weight: bold;
    ${theme.below.lg} {
      margin-bottom: 10px;
    }
  }
  .info-row {
    padding: 0 20px;
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;
    grid-gap: 50px;

    ${theme.below.lg} {
      flex-wrap: wrap;
      justify-content: center;
      grid-gap: 10px;
    }
  }
  .info-row-item {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    ${theme.below.lg} {
      min-width: 35%;
      flex: none;
      &:last-child {
        width: 100%;
      }
    }
  }
  .info-row-item-icon {
    display: block;
    .star-number {
      margin-left: 4px;
    }
  }
  .info-row-item-value {
    position: relative;
    display: block;
    font-size: 28px;
    font-weight: bold;
    width: fit-content;

    .thin {
      font-size: 18px;
      font-weight: 200;
      position: absolute;
      bottom: 5px;
      right: -22px;
    }
  }
  .info-row-item-title {
    display: block;
    font-size: 16px;
  }
`;
const Reviews = styled('div')`
  width: 100%;

  .review-item-container {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 170px;

    .review-item-reply {
      flex: 1;
      position: relative;
      background: #d8e6dd;
      padding: 20px;
    }

    .triangle {
      position: absolute;
      top: -15px;
      left: 50%;
      transform: translateX(-50%);
      margin: auto;
      width: 0;
      height: 0;
      border-bottom: 15px solid #d8e6dd;
      border-left: 17px solid transparent;
      border-right: 17px solid transparent;
    }
  }

  .review-item {
    flex: 1;
    display: block;
    background: #fff;
    padding: 15px;
    height: 100%;
  }
  .review-item-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .review-item-stars {
    text-align: left;
    .star-rating {
      justify-content: flex-start;
    }
    .end {
      stop-color: #bbbbbb;
    }
  }
  .review-item-name-date {
    text-align: right;
    font-size: 11px;
  }
  .review-item-title {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
  }
  .review-item-text {
    font-size: 14px;
    line-height: 1.4;
    margin-top: 20px;
    text-align: left;
    position: relative;
  }
  .short-text {
    ::after {
      content: '...';
    }
  }

  .box-shading {
    width: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 95%
    );
    position: absolute;
    bottom: 0;
    height: 20px;
  }
`;

const FlexWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  max-width: 100%;

  /* .review-item-container {
    max-width: 25%;
  }

  ${theme.below.lg} {
    .review-item-container {
      max-width: 50%;
    }
  }
  ${theme.below.md} {
    .review-item-container {
      max-width: 100%;
    }
  } */
`;

const ProductReviewCard = ({ review }) => {
  /**
   * The isEmpty function checks if an object is empty.
   * @param obj Pass in an object to the function
   * @return True if the object has no properties, and false if the object has at least one property
   * @docauthor Anders Zetterström @ R3
   */
  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  const textLength = 180;

  const shortText = review?.text?.substring(0, textLength);
  const fullText = review?.text?.substring(0, review?.text?.length);

  const [replyText, setReplyText] = React.useState(shortText);

  return (
    <div className="review-item-container">
      <div className="review-item">
        <div className="review-item-header">
          <div className="review-item-stars">
            {review?.rating && (
              <LipscoreProductRating
                rating={review?.rating}
                identifier={review?.date + review?.user.short_name}
              />
            )}
          </div>
          <div className="review-item-name-date">
            {review?.user?.short_name}
            <br />
            {review?.created_at?.split('T')[0]}
          </div>
        </div>
        <div className="review-item-title"></div>
        <div
          className="review-item-text"
          onClick={() =>
            setReplyText(replyText === fullText ? shortText : fullText)
          }
        >
          <p
            className={
              fullText.length > textLength &&
              replyText === shortText &&
              'short-text'
            }
            dangerouslySetInnerHTML={{
              __html: replyText
            }}
          />
          {fullText.length > textLength && replyText === shortText && (
            <div className="box-shading" />
          )}
        </div>
      </div>
      {/* {review?.review_reply && !isEmpty(review?.review_reply) && (
        <div className={'review-item-reply'}>
          <div class="triangle" />
          <div className="review-item-header">
            <div className="review-item-stars"></div>
            <div className="review-item-name-date">
              {t('Answer from Life')}
              <br />
              {review?.review_reply?.created_at?.split('T')[0]}
            </div>
          </div>
          <div className="review-item-title">{t('Answer from Life')}</div>
          <div className="review-item-text">
            <p
              dangerouslySetInnerHTML={{
                __html: review?.review_reply?.text
              }}
            />
          </div>
        </div>
      )} */}
    </div>
  );
};

const ProductReviews = ({ data, product }) => {
  const filteredReviews = data?.reviews?.filter(review => review?.rating > 3);
  const desktopThresholdReviewsValue = 3;
  const mobileThresholdReviewsValue = 1;

  return (
    <ReviewWrapper id="review-wrapper">
      {/* <h2 className="review-block-title">{t('Reviews')}</h2> */}
      {/* <div className="info-row">
        <div className="info-row-item">
          <div className="info-row-item-icon">
            <SVG name="Star" />
          </div>
          <div className="info-row-item-value">
            {parseFloat(data?.rating).toFixed(1)}
            <span className="thin">/5</span>
          </div>
          <div className="info-row-item-title">{t('Average rating')}</div>
        </div>
        <div className="info-row-item">
          <div className="info-row-item-icon">
            <SVG name="StarNumber" className="star-number" />
          </div>
          <div className="info-row-item-value">{data?.review_count}</div>
          <div className="info-row-item-title">{t('Number of reviews')}</div>
        </div>
      </div> */}

      <Reviews>
        <Above breakpoint="lg">
          {matches => (
            <>
              {filteredReviews?.length >
              (matches
                ? desktopThresholdReviewsValue
                : mobileThresholdReviewsValue) ? (
                <ReviewSwiper slidesPerView={matches ? 2.5 : 1.1}>
                  {filteredReviews?.map((review, index) => (
                    <ProductReviewCard key={index} review={review} />
                  ))}
                </ReviewSwiper>
              ) : (
                <FlexWrapper>
                  {filteredReviews?.map((review, index) => (
                    <ProductReviewCard key={index} review={review} />
                  ))}
                </FlexWrapper>
              )}
            </>
          )}
        </Above>
      </Reviews>
    </ReviewWrapper>
  );
};

export default ProductReviews;
