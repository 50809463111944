import React, { useRef } from 'react';
import { css, cx } from 'emotion';
// import { SwiperButton } from '../../../Layout/Swiper/SwiperButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import { theme } from '../../../Theme';

const reviewSwiperStyle = css`
  display: flex;
  justify-content: center;
  position: relative;
  .review-swiper {
    width: 88vw;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: fit-content;
    :after {
      display: none;
    }
  }

  .swiper-button-prev {
    left: 2vw;
  }
  .swiper-button-next {
    right: 2vw;
  }
`;

export const ReviewSwiper = ({ slidesPerView, children }) => {
  const swiperRef = useRef(null);
  const handlePrevClick = () => {
    swiperRef?.current?.swiper?.slidePrev();
  };
  const handleNextClick = () => {
    swiperRef?.current?.swiper?.slideNext();
  };
  return (
    <div className={cx('review-swiper', reviewSwiperStyle)}>
      <div className="swiper-button-prev">
        {/* <SwiperButton direction={'left'} callback={handlePrevClick} /> */}
      </div>
      <Swiper
        ref={swiperRef}
        effect={'cover'}
        grabCursor={true}
        threshold={2}
        centeredSlides={false}
        slidesPerView={slidesPerView}
        className="review-swiper"
        spaceBetween={20}
      >
        {children?.map((child, index) => (
          <SwiperSlide
            key={'review-swiper-slide-' + index}
            className={'swiper-slide'}
          >
            {child}
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-button-next">
        {/* <SwiperButton direction={'right'} callback={handleNextClick} /> */}
      </div>
    </div>
  );
};
