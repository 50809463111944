import React from 'react';
import styled from 'react-emotion';

import { Above, Below } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image';
import theme from '@jetshop/ui/utils/theme';

import { MainSectionWrapper } from '../../StartPage/StartPage';
import { Link } from 'react-router-dom';

const CtaButton = styled('span')`
  display: inline-block;
  color: black;
  text-decoration: none;
  padding: 0 25px;
  height: 50px;
  line-height: 50px;
  background: white;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  font-family: ${theme('fonts.body')};
  font-weight: bold;
  transition: all, 0.2s ease;
  min-width: 160px;
  &:hover {
    background: black;
    color: white;
  }

  ${theme('below.lg')} {
    font-size: 12px;
    line-height: 45px;
    height: 45px;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const Container = styled('div')`
  margin-bottom: 40px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  ${theme('below.lg')} {
    grid-template-columns: 1fr 1fr;
    padding: 5px;
  }

  * {
    font-family: ${theme('fonts.body')};
  }
`;

const BlockLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const Column = styled('div')`
  width: 50%;

  ${theme('below.lg')} {
    width: 100%;
  }
`;
const Box = styled('div')`
  width: 100%;
  position: relative;
  padding: 0.5em;
  ${theme('below.lg')} {
    padding: 5px;
  }

  &.outlet {
    .cta-button {
      background: ${theme('colors.outletRed')};
      color: white;
    }
    h1,
    h2,
    h3 {
      color: ${theme('colors.outletRed')};
      text-shadow: 0px 0px 20px black;
    }
  }
`;
const BoxImage = styled('div')`
  position: relative;
  display: flex;
  align-items: center;

  img  {
    width: 100%;
  }
`;
const BoxContent = styled('div')`
  color: ${props => props.textcolor};
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  right: 0;
  padding: 5px;
  text-align: center;
  display: flex;

  &.right {
    justify-content: flex-end;
  }

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.top {
    align-items: flex-start;
  }

  &.bottom {
    align-items: flex-end;
  }

  &.middle {
    align-items: center;
  }

  .white {
    color: white;
  }
  .black {
    color: black;
  }

  > div {
    height: fit-content;
    margin: 1rem;
  }

  h2 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 15px;
    letter-spacing: 1px;
    font-weight: bold;
    text-shadow: 0px 0px 10px #7a7a7a;
  }
`;

const Wrap = styled('div')`
  padding: 0.5rem 0;
  > div {
    padding: 0 1.5rem;
    ${theme('below.lg')} {
      padding: 0;
    }
  }
`;

const BoxItem = ({
  image,
  title,
  color,
  buttonText,
  buttonUrl,
  responsive,
  className,
  position,
  aspect,
  mobileAspect,
  mobileImage
}) => {
  return (
    <Box className={className}>
      <BlockLink to={buttonUrl}>
        <BoxImage className={color}>
          {image?.indexOf('.gif') > -1 ? (
            <img src={image} alt={title} />
          ) : (
            <>
              <Above breakpoint="lg">
                <Image aspect={aspect || '5:4'} cover src={image} alt={title} />
              </Above>
              <Below breakpoint="lg">
                <Image
                  aspect={mobileAspect || '3:4'}
                  cover
                  src={mobileImage || image}
                  alt={title}
                />
              </Below>
            </>
          )}
        </BoxImage>
        <BoxContent
          textcolor={color ? color : 'white'}
          className={'responsive-' + responsive + ' ' + position}
        >
          <div>
            <h2>{title}</h2>
            {buttonText ? (
              <CtaButton className="cta-button" to={buttonUrl}>
                {buttonText}
              </CtaButton>
            ) : null}
          </div>
        </BoxContent>
      </BlockLink>
    </Box>
  );
};

const BoxRow = ({ boxes }) => {
  return (
    <Wrap>
      <MainSectionWrapper>
        <Container>
          {boxes.map((box, index) => {
            return <BoxItem key={index} {...box} />;
          })}
          <Column></Column>
        </Container>
      </MainSectionWrapper>
    </Wrap>
  );
};

export default BoxRow;
