import React from 'react';
import styled from 'react-emotion';
import { Query } from 'react-apollo';
import { theme } from '../../Theme';
import productRowQuery from '../../Shortcodes/ProductRowQuery.gql';
import { ProductGrid } from '../../CategoryPage/ProductGrid';

const Wrapper = styled('div')`
  width: 100%;
  max-width: 1280px;
  padding: 1rem 2rem;
  margin: 1rem auto;

  &.not-product-page {
    ul {
      justify-content: center;
      ${({ theme }) => theme.below.lg} {
        justify-content: flex-start;
        padding: 0;
        /* max-width: calc(100vw - 40px); */
      }
    }
  }
  ${({ theme }) => theme.below.lg} {
    padding: 0;
  }

  .product-card {
  }

  h2 {
    font-family: ${({ theme }) => theme.fonts.body};
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
    line-height: 1;
    font-weight: bold;
    letter-spacing: 1px;
  }

  ${({ theme }) => theme.below.lg} {
    padding-bottom: 0;
    > ul {
      padding: 0 20px;
      .product-card {
        min-width: 55vw;
        /* flex: none; */
        a {
          margin-bottom: 0;
        }
      }

      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      overflow-scrolling: touch;
      &::-webkit-scrollbar,
      &::-webkit-scrollbar-track,
      ::-webkit-scrollbar-thumb {
        background-color: transparent;
        display: none;
      }
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &.product-page {
    ul.product-grid {
      ${theme.above.lg} {
        justify-content: center;
      }
    }
    .product-card {
      ${theme.above.lg} {
        flex: none;
      }
    }
  }
`;

const ProductRow = ({ products, title, productPage }) => {
  products = products.trim().split(',');
  return (
    <Query
      variables={products && { articleNumbers: products }}
      query={productRowQuery}
      ssr={true}
      errorPolicy={'ignore'}
    >
      {({ loading, error, data }) => {
        if (loading || error) return null;
        if (data && !data.products.length) return null;
        return (
          <Wrapper
            className={productPage ? 'product-page' : 'not-product-page'}
          >
            {title ? <h2>{title}</h2> : null}
            <ProductGrid products={data.products} loading={loading} />
          </Wrapper>
        );
      }}
    </Query>
  );
};

export default ProductRow;
