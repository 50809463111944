import React, { useEffect, useState } from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';

import MaxWidth from '../../Layout/MaxWidth';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-light-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
library.add(faHeart, faInstagram);

const InstagramWrapper = styled('div')`
  width: 100%;
  > div {
    ${theme('below.md')} {
      padding: 0 10px;
    }
  }
`;

const InstagramContentWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;

  ${theme('below.md')} {
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0;
    height: auto;
    display: flex;
  }
`;

const InstagramItem = styled('div')`
  width: 25%;
  width: 100%;
  display: block;
  padding: 0 5px;
  padding: 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  line-height: 18px;
  font-size: 14px;
  position: relative;

  &:first-of-type {
    grid-column: 3 / 5;
    grid-row: 1 / 3;
  }

  &:hover {
    div {
      opacity: 1;
      visibility: visible;
    }
  }

  > svg {
    height: 32px;
    margin-bottom: 16px;
  }

  ${theme('below.md')} {
    width: 50%;
    padding: 10px;
    &:last-child {
      display: none;
    }
  }
`;

export const InstagramImageWrapper = styled('div')`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.02);
    z-index: 1;
    pointer-events: none;
  }
`;

export const InstagramOverlay = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: rgba(255, 255, 255, 0.6);
  transition: all, 0.3s;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  visibility: hidden;

  span {
    color: #000;
    font-size: 20px;
  }
`;

const HeartIcon = styled(FontAwesomeIcon)`
  color: #000;
  font-size: 32px;
  margin: 0 10px;
`;

const InstagramHeader = styled('div')`
  text-align: center;
  margin-bottom: 10px;
  h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    font-family: ${theme('fonts.cond')};
    line-height: 1;
    font-weight: normal;
    letter-spacing: 1px;
    ${theme('below.sm')} {
      font-size: 2rem;
    }
  }
  i {
    display: block;
    font-size: 1.5rem;
  }
  a {
    text-decoration: none;

    h2,
    h3,
    svg {
      color: #333;
      transition: all, 0.2s ease;
    }
    &:hover {
      h2,
      h3,
      svg {
        color: #ada9a9;
      }
    }
  }
`;

const Instagram = () => {
  const accountId = '17841400878077936';
  const accessToken =
    'EAAJYiZBFXOaYBANP09MZChwq3DBi8HLKZBtoLYKlpg8ix5WyHHBZCGIDIARPA3gsmTcDfQkQ3QI44D0dLzbRfMZCoOQWTv5SnFoFxMyRyKT0bCwRTgfXmY5EJXHnZAGi9KrNZAjkab6iVZA8cwuPBuDtZCZCYkVH4ssn7PSCkvxsZCW8gZDZD';
  const [images, setImages] = useState([]);
  useEffect(() => {
    fetch(
      `https://graph.facebook.com/${accountId}/media?limit=7&fields=like_count,thumbnail_url,media_url,permalink&access_token=${accessToken}`
    )
      .then(response => response.json())
      .then(json => {
        setImages(json.data);
      });
  }, [images]);
  return (
    <InstagramWrapper>
      <MaxWidth>
        {images && (
          <React.Fragment>
            <InstagramHeader>
              <a href="https://www.instagram.com/clnathletics" target="_blank">
                <h2>Instagram: @clnathletics</h2>
              </a>
            </InstagramHeader>
            <InstagramContentWrapper>
              {images.map(image => (
                <InstagramItem key={image.id}>
                  <a
                    href={image.permalink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramImageWrapper
                      style={{
                        backgroundImage: `url(${
                          image[
                            image.thumbnail_url !== undefined
                              ? 'thumbnail_url'
                              : 'media_url'
                          ]
                        })`
                      }}
                    />
                    <InstagramOverlay>
                      <HeartIcon icon={['fal', 'heart']} />
                      <span>{image.like_count}</span>
                    </InstagramOverlay>
                  </a>
                </InstagramItem>
              ))}
            </InstagramContentWrapper>
          </React.Fragment>
        )}
      </MaxWidth>
    </InstagramWrapper>
  );
};

export default Instagram;
