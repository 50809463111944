import React from 'react';
import styled from 'react-emotion';
import MaxWidth from '../../Layout/MaxWidth';
import Image from '@jetshop/ui/Image/Image';
import { TrendLink } from '../../ui/Button';

const Container = styled('div')`
  padding-top: 110px;
  padding-bottom: 142px;
  ${({ theme }) => theme.below.sm} {
    padding-top: 62px;
    padding-bottom: 96px;
  }
`;

const Title = styled('h1')`
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 18px;
  ${({ theme }) => theme.below.sm} {
    font-size: 26px;
    margin-bottom: 12px;
  }
`;

const Description = styled('p')`
  width: 400px;
  max-width: 100%;
  margin-bottom: 18px;
  line-height: 1.45em;
  ${({ theme }) => theme.below.sm} {
    font-size: 14px;
    width: 220px;
  }
`;

const StyledButton = styled(TrendLink)`
  width: 176px;
  height: 38px;
  ${({ theme }) => theme.below.sm} {
    width: 138px;
    height: 32px;
    font-size: 14px;
  }
`;

const StartPageHero = ({ hero }) => (
  <Image fillAvailableSpace={true} src={hero.image.url}>
    <Container>
      <MaxWidth>
        <Title>{hero.title}</Title>
        <Description>{hero.description}</Description>
        <StyledButton to={hero.button.url}>{hero.button.text}</StyledButton>
      </MaxWidth>
    </Container>
  </Image>
);

export default StartPageHero;
