import { Below } from '@jetshop/ui/Breakpoints';
import React from 'react';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CTALink = styled(Link)`
  flex: 1;
  color: inherit;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  height: 45px;
  line-height: 45px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  &:first-child {
    border-right: 1px solid #ddd;
  }
`;

const ContentBlock = ({ buttons }) => {
  return (
    <>
      <Below breakpoint="lg">
        <Wrapper>
          {buttons?.map((button, index) => (
            <CTALink key={index} to={button.link}>
              {button.text}
            </CTALink>
          ))}
        </Wrapper>
      </Below>
    </>
  );
};

export default ContentBlock;
