import React from 'react';
import { toast } from 'react-toastify';
import styled from 'react-emotion';

import AddToCart from '@jetshop/core/components/Mutation/AddToCart';
import t from '@jetshop/intl';
import theme from '@jetshop/ui/utils/theme';

import UIButton from '../../ui/Button';
import ProductToast from './ProductToast';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBag } from '@fortawesome/pro-light-svg-icons';
library.add(faShoppingBag);

const AddToCartIcon = styled(FontAwesomeIcon)`
  font-size: 1.4rem;
  color: white;
  margin-left: 15px;
`;

const Button = styled(UIButton)`
  background: ${({ theme }) => theme.colors.lightBlue};
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 300;
  ${theme('above.lg')} {
    margin-bottom: 10px;
  }
`;

export default ({ product, quantity = 1, inStock }) => {
  return (
    <AddToCart
      onAddToCart={selectedVariation =>
        toast(
          <ProductToast
            selectedVariation={selectedVariation}
            product={product}
          />,
          {
            autoClose: 2000
          }
        )
      }
    >
      {(addToCart, missingOption) => (
        <Button
          disabled={missingOption || !inStock}
          css={(missingOption || !inStock) && { opacity: 0.5 }}
          onClick={() => addToCart(product, quantity)}
          data-testid="add-to-cart"
        >
          {missingOption
            ? t('Please select {option}', { option: missingOption.name })
            : t('Add to cart')}
          <AddToCartIcon icon={['fal', 'shopping-bag']} />
        </Button>
      )}
    </AddToCart>
  );
};
