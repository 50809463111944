import React from 'react';
import styled from 'react-emotion';

import { Above, Below } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image';
import theme from '@jetshop/ui/utils/theme';

const Wrapper = styled('div')`
  width: 100%;
  max-width: 76rem;
  margin: auto;
  position: relative;
  ${theme('below.lg')} {
  }
`;
const Content = styled('div')`
  color: ${props => props.textcolor};
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  right: 0;
  padding: 5px;
  text-align: center;
  display: flex;

  &.right {
    justify-content: flex-end;
  }

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.top {
    align-items: flex-start;
  }

  &.bottom {
    align-items: flex-end;
  }

  &.middle {
    align-items: center;
  }

  .white {
    color: white;
  }
  .black {
    color: black;
  }

  > div {
    height: fit-content;
    margin: 1rem;
  }

  h1 {
    font-size: 3.5rem;
    margin: 0 0 15px 0;
    line-height: 1;
    font-weight: bold;
    text-shadow: 0px 0px 10px #7a7a7a;
    ${theme('below.lg')} {
      font-size: 2.5rem;
    }
  }
`;

export const BlockLink = styled('a')`
  color: inherit;
  text-decoration: none;
  img {
    max-width: 100%;
  }
`;

const CTA = styled('a')`
  display: inline-block;
  padding: 0 20px;
  line-height: 50px;
  height: 50px;
  margin: 10px;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: bold;
  transition: all, 0.2s ease;
  color: inherit;
  text-decoration: none;
  min-width: 160px;
  border: 1px solid;
  &:hover {
    background: black !important;
    border-color: black !important;
    color: white !important;
  }
  ${theme('below.lg')} {
    font-size: 12px;
    margin: 5px;
    line-height: 45px;
    height: 45px;
    min-width: 150px;
  }
`;

const CTAWrapper = styled('div')`
  ${theme('below.lg')} {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

const ContentWrapper = styled('div')`
  ${theme('below.lg')} {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    margin: 0 !important;
    padding-bottom: 25px;
  }
`;

const FeaturedNew = ({
  imageSrc,
  mobileImage,
  title,
  link,
  buttonText,
  textColor,
  buttons,
  position = 'center middle',
  aspect,
  mobileAspect
}) => {
  return (
    <Wrapper>
      {buttons.length < 1 ? (
        <BlockLink href={link} target="_blank">
          {imageSrc?.indexOf('.gif') > -1 ? (
            <img src={imageSrc} alt={title} />
          ) : (
            <>
              <Above breakpoint="lg">
                <Image
                  aspect={aspect || '16:9'}
                  cover
                  src={imageSrc}
                  alt={title}
                />
              </Above>
              <Below breakpoint="lg">
                <Image
                  aspect={mobileAspect || '3:4'}
                  cover
                  src={mobileImage || imageSrc}
                  alt={title}
                />
              </Below>
            </>
          )}
          <Content
            textcolor={textColor ? textColor : 'white'}
            className={position}
          >
            <div>
              <h1>{title}</h1>
              {/* {link && buttonText && <CTA>{buttonText}</CTA>} */}
            </div>
          </Content>
        </BlockLink>
      ) : (
        <>
          {imageSrc?.indexOf('.gif') > -1 ? (
            <img src={imageSrc} alt={title} />
          ) : (
            <>
              <Above breakpoint="lg">
                <Image
                  aspect={aspect || '16:9'}
                  cover
                  src={imageSrc}
                  alt={title}
                />
              </Above>
              <Below breakpoint="lg">
                <Image
                  aspect={mobileAspect || '5:4'}
                  cover
                  src={mobileImage || imageSrc}
                  alt={title}
                />
              </Below>
            </>
          )}
          <Content
            textcolor={textColor ? textColor : 'white'}
            className={position}
          >
            <ContentWrapper>
              <h1>{title}</h1>
              {/* {link && buttonText && <CTA>{buttonText}</CTA>} */}
              <CTAWrapper>
                {buttons?.map(button => (
                  <CTA
                    style={{
                      background: `${button.background}`,
                      color: `${button.textColor}`,
                      borderColor: `${button.borderColor}`
                    }}
                    href={button.link}
                    target="_blank"
                  >
                    {button.buttonText}
                  </CTA>
                ))}
              </CTAWrapper>
            </ContentWrapper>
          </Content>
        </>
      )}
    </Wrapper>
  );
};

export default FeaturedNew;
